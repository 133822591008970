// eslint-disable-next-line import/no-cycle
import { axiosClient } from './config';

class AuthServices {
  static login(params: any) {
    const url = 'user/authenticate';
    return axiosClient.post(url, params);
  }

  static remind(params: any) {
    const url = 'user/remind';
    return axiosClient.post(url, params);
  }

  static getProfile() {
    const url = 'user/profile';
    return axiosClient.get(url);
  }

  static getToken(params: any) {
    const url = 'token/detail';
    return axiosClient.get(url, { params });
  }
}
export default AuthServices;
