import routes from '../constants/routes';

import Login from './auth/login';
import Remind from './auth/remind';
import PasswordReset from './auth/password_reset';
import Dashboard from './dashboard';
import Purchase from './purchase';
import InputEmail from './purchase/inputEmail';
import SendConfirmEmail from './purchase/sendConfirmEmail';

import MUserCreate from './muser/create';
import MUserEdit from './muser/edit';
import MUserSearch from './muser/search';

import UserCreate from './user/create';
import UserEdit from './user/edit';
import UserSearch from './user/search';
import UserImport from './user/import';
import ChangePassword from './user/changePassword';

import GroupSearch from './group/search';
import GroupCreate from './group/create';
import GroupEdit from './group/edit';
import PasswordPolicy from './group/password_policy/index';
import LicenseSearch from './license/search';
import LicenseMailEndDateTrial10Day from './license/mailEndDateTrial10Day';
import MailLicenseEndDate60Day from './license/mailEndDate60Day';
import MailLicensePayment from './license/mailPayment';
import LicenseCreate from './license/create';
import LicenseEdit from './license/edit';
import LicenseUser from './license/user/index';
import LicenseDevice from './license/device/index';
import LicenseImport from './license/import';
import VersionCreate from './version/create';
import VersionSearch from './version/search';
import VersionEdit from './version/edit';
import UserInfoReadMessageGet from './version/getUserInfoReadMessage';
import Link from './link';
import UserLicenseImport from './license/user/import';
import PasswordExpiredChange from './auth/password_expired_change';
import CreateInformationBuyer from './purchase/createInformationBuyer';
import InformationBuyer from './purchase/informationBuyer';
import InformationTrial from './purchase/informationTrial';
import InforBuyerNotify from './purchase/infoBuyerNotify';
import CreateInformationTrial from './purchase/createInformationTrial';
import BuyLicense from './purchase/buy_license/buyLicense';
import UpdateStop from './purchase/buy_license/updateStop';
import ReopenLicenses from './purchase/buy_license/reopenLicenses';
import BuyAdditionalLicenses from './purchase/buy_license/buyAdditionalLicenses';
import PageNotFound from './notfound';
import EventTrial from './event/trial';
import AddBuyerNotify from './purchase/addBuyerNotify';
import ConfirmInfo from './purchase/buy_license/confirmInfo';
import ConfirmInfoFromTrial from './purchase/buy_license/confirmInfoFromTrial';
import AddLicenseConfirm from './purchase/buy_license/addLicenseConfirm';
import ConfirmUpdateBank from './purchase/buy_license/confirmUpdateBank';
import ExpiredPage from './expiredPage';
import ChangePaymentMethod from './purchase/changePaymentMethod';
import ChangePaymentMethodSuccess from './purchase/changePaymentMethodSuccess';
import StartLicense from './purchase/buy_license/startLicense';

const routesPages = [
  {
    path: routes.dashboard,
    main: () => <Dashboard />,
  },
  {
    path: routes.purchase,
    noLayout: true,
    main: () => <Purchase />,
  },
  {
    path: routes.inputEmail,
    noLayout: true,
    main: () => <InputEmail />,
  },
  {
    path: routes.sendConfirmEmail,
    noLayout: true,
    main: () => <SendConfirmEmail />,
  },
  {
    path: routes.login,
    noLayout: true,
    main: () => <Login />,
  },
  {
    path: routes.remind,
    noLayout: true,
    main: () => <Remind />,
  },
  {
    path: routes.passwordReset,
    noLayout: true,
    main: () => <PasswordReset />,
  },
  {
    path: routes.passwordExpiredChange,
    noLayout: true,
    main: () => <PasswordExpiredChange />,
  },
  {
    path: routes.muserCreate,
    main: () => <MUserCreate />,
  },
  {
    path: routes.muserEdit,
    main: () => <MUserEdit />,
  },
  {
    path: routes.muserSearch,
    main: () => <MUserSearch />,
  },
  {
    path: routes.userCreate,
    main: () => <UserCreate />,
  },
  {
    path: routes.userEdit,
    main: () => <UserEdit />,
  },
  {
    path: routes.userSearch,
    main: () => <UserSearch />,
  },
  {
    path: routes.userImport,
    main: () => <UserImport />,
  },
  {
    path: routes.groupSearch,
    main: () => <GroupSearch />,
  },
  {
    path: routes.groupCreate,
    main: () => <GroupCreate />,
  },
  {
    path: routes.groupEdit,
    main: () => <GroupEdit />,
  },
  {
    path: routes.licenseSearch,
    main: () => <LicenseSearch />,
  },
  {
    path: routes.licenseMailEndDateTrial10Day,
    main: () => <LicenseMailEndDateTrial10Day />,
  },
  {
    path: routes.licenseMailEndDate60Day,
    main: () => <MailLicenseEndDate60Day />,
  },
  {
    path: routes.licenseMailPayment,
    main: () => <MailLicensePayment />,
  },
  {
    path: routes.licenseCreate,
    main: () => <LicenseCreate />,
  },
  {
    path: routes.licenseEdit,
    main: () => <LicenseEdit />,
  },
  {
    path: routes.licenseUser,
    main: () => <LicenseUser />,
  },
  {
    path: routes.licenseDevice,
    main: () => <LicenseDevice />,
  },
  {
    path: routes.licenseImport,
    main: () => <LicenseImport />,
  },
  {
    path: routes.userLicenseImport,
    main: () => <UserLicenseImport />,
  },
  {
    path: routes.passwordPolicy,
    main: () => <PasswordPolicy />,
  },
  {
    path: routes.link,
    noLayout: true,
    main: () => <Link />,
  },
  {
    path: routes.changePassword,
    main: () => <ChangePassword />,
  },
  {
    path: routes.versionCreate,
    main: () => <VersionCreate />,
  },
  {
    path: routes.versionHistory,
    main: () => <VersionSearch />,
  },
  {
    path: routes.versionEdit,
    main: () => <VersionEdit />,
  },
  {
    path: routes.userinfoReadMessage,
    main: () => <UserInfoReadMessageGet />,
  },
  {
    path: routes.eventSettingTrial,
    main: () => <EventTrial />,
  },
  {
    path: routes.notFound,
    noLayout: true,
    main: () => <PageNotFound />,
  },
  {
    path: routes.buyerInformation,
    noLayout: true,
    main: () => <CreateInformationBuyer />,
  },
  {
    path: routes.confirmInformation,
    noLayout: true,
    main: () => <ConfirmInfo />,
  },
  {
    path: routes.buyTrialConfirm,
    noLayout: true,
    main: () => <ConfirmInfoFromTrial />,
  },
  {
    path: routes.addLicenseConfirm,
    noLayout: true,
    main: () => <AddLicenseConfirm />,
  },
  {
    path: routes.trialInformation,
    noLayout: true,
    main: () => <CreateInformationTrial />,
  },
  {
    path: routes.successBuyerInformation,
    noLayout: true,
    main: () => <InformationBuyer />,
  },
  {
    path: routes.successTrialInformation,
    noLayout: true,
    main: () => <InformationTrial />,
  },
  {
    path: routes.infoBuyerNotify,
    noLayout: true,
    main: () => <InforBuyerNotify />,
  },
  {
    path: routes.AddBuyerNotify,
    noLayout: true,
    main: () => <AddBuyerNotify />,
  },
  {
    path: routes.buyLicense,
    noLayout: true,
    main: () => <BuyLicense />,
  },
  {
    path: routes.updateStop,
    noLayout: true,
    main: () => <UpdateStop />,
  },
  {
    path: routes.startLicense,
    noLayout: true,
    main: () => <StartLicense />,
  },
  {
    path: routes.reopenLicense,
    noLayout: true,
    main: () => <ReopenLicenses />,
  },
  {
    path: routes.buyAdditionalLicenses,
    noLayout: true,
    main: () => <BuyAdditionalLicenses />,
  },
  {
    path: routes.pageNotFound,
    noLayout: true,
    main: () => <PageNotFound />,
  },
  {
    path: routes.updateBankTransfer,
    noLayout: true,
    main: () => <UpdateStop />,
  },
  {
    path: routes.confirmUpdateBank,
    noLayout: true,
    main: () => <ConfirmUpdateBank />,
  },
  {
    path: routes.expiredPage,
    noLayout: true,
    main: () => <ExpiredPage />,
  },
  {
    path: routes.changePaymentMethod,
    noLayout: true,
    main: () => <ChangePaymentMethod />,
  },
  {
    path: routes.changePaymentMethodSuccess,
    noLayout: true,
    main: () => <ChangePaymentMethodSuccess />,
  },
];

export default routesPages;
